import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { useTranslation } from "react-i18next"
import loadable from "@loadable/component"
import { Link as GatsbyLink } from "gatsby"
import {
  containerSmallMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_X_LARGE
} from "../../constants"
import Logo from "../../images/cest-normal-logo-black.png"
import Link from "../Link"
import Button from "../ui/Button"
import CountdownBanner from "../widgets/CountdownBanner"

const NewsletterPopup = loadable(() => import("../NewsletterPopup"))

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${containerSmallMaxWidth};
  padding: 0 2rem;
`

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${MEDIA_MIN_MEDIUM} {
      justify-content: flex-start;
      width: auto;
    }

    h1 {
      font-size: 2.8rem;
      padding-top: 0.2rem;

      ${MEDIA_MIN_MEDIUM} {
        padding-left: 3rem;
        margin-left: 3rem;
        border-left: 0.1rem solid ${(p) => p.theme.colors.black};
      }
    }
  }

  svg,
  img {
    display: none;

    ${MEDIA_MIN_MEDIUM} {
      display: block;
      width: 12rem;
      padding: 3.5rem 0 3.2rem;
      fill: ${(p) => p.theme.colors.black};
    }

    ${MEDIA_MIN_X_LARGE} {
      width: 20rem;
    }
  }
`

const GoBack = styled(GatsbyLink)`
  position: absolute;
  left: 0;
  top: 1.8rem;
  width: 5rem;

  ${MEDIA_MIN_MEDIUM} {
    position: relative;
    top: auto;
    width: auto;
  }
`

const GoBackButton = styled(Button)`
  background: transparent;
  padding: 0;

  span {
    display: none;
  }

  i {
    font-size: 3.6rem;
    display: inline-block;
  }

  ${MEDIA_MIN_MEDIUM} {
    border: 0.1rem solid ${(p) => p.theme.colors.black};
    padding: 0 3rem;

    i {
      display: none;
    }

    span {
      display: inline-block;
    }
  }

  ${MEDIA_MIN_X_LARGE} {
    &:hover {
      background: ${(p) => p.theme.colors.primary};
      border: 0.1rem solid ${(p) => p.theme.colors.primary};
      color: ${(p) => p.theme.colors.white};
    }
  }
`

const Help = styled(Link)`
  position: absolute;
  right: 0;
  top: 3rem;
  color: ${(p) => p.theme.colors.black};
  display: flex;
  align-items: center;
  margin-right: 2rem;

  i {
    font-size: 2rem;
  }

  span {
    display: none;
  }

  ${MEDIA_MIN_MEDIUM} {
    position: relative;
    margin-right: 2rem;
    top: auto;

    span {
      display: block;
      margin-right: 1rem;
    }
  }
`

const Header = () => {
  const { t } = useTranslation("translations")
  const { colors } = useContext(ThemeContext)

  return (
    <>
      <Wrapper>
        <Container>
          <NewsletterPopup />
          <div>
            <Link to="/">
              <img src={Logo} alt="C'est Normal logo" />
            </Link>
            <h1>{t("Checkout")}</h1>
          </div>
          <div>
            <Help to="/faq/" title={t("Support")}>
              <span>{t("Support")}</span>
              <i className="fal fa-user-headset"></i>
            </Help>
            <GoBack color={colors.black} to="/">
              <GoBackButton>
                <i className="fal fa-angle-left"></i>
                <span> {t("Continue shopping")}</span>
              </GoBackButton>
            </GoBack>
          </div>
        </Container>
        {/* <CountdownBanner /> */}
      </Wrapper>
    </>
  )
}

export default Header
