import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { useTranslation } from "react-i18next"
import { CountryRegionData } from "react-country-region-selector"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import Link from "../Link"
import Dinero from "dinero.js"

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 2.2rem 0 2rem;

  &:first-of-type {
    border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.8rem;
    padding-right: 8rem;
  }

  img {
    height: 2rem;
    margin-right: 1rem;
    max-width: 9rem;
    padding-top: 0.2rem;
  }
`

const ShippingRow = styled(Row)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  span {
    margin-bottom: 1rem;
  }

  div {
    display: flex;
    align-items: center;
    flex: 1;
    white-space: nowrap;
  }
`

const ChangeLink = styled(Link)`
  color: ${(p) => p.theme.colors.primary};
  height: 1.4rem;
  flex-shrink: 0;
  margin-left: 1rem;
  position: absolute;
  right: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    right: 3rem;
  }

  &:after {
    bottom: -0.6rem;
  }
`

const Label = styled.span`
  display: inline-block;
  width: 100%;
  margin-bottom: 0.5rem;
  font-weight: 600;
`

const Summary = ({ setShowPayment, setShowSummary }) => {
  const { t } = useTranslation("translations")
  const { colors } = useContext(ThemeContext)
  const {
    billingAddress,
    shippingAddress,
    shippingMethod,
    currentStore,
    languageCode
  } = useContext(BrinkContext)

  const getFullCountryName = (countryCode) =>
    CountryRegionData.find((country) => country[1] === countryCode)

  const { currencyUnit } = currentStore
  const Amount = Currency(currencyUnit)

  const shippingPrice = Amount(shippingMethod.price).setLocale(languageCode)

  return (
    <>
      <Row>
        <div>
          <Label>{t("E-mail")}:</Label> {billingAddress.email}
        </div>
        <ChangeLink
          to="/"
          underline
          color={colors.primary}
          onClick={(e) => {
            e.preventDefault()
            setShowPayment(false)
            setShowSummary(false)
          }}
        >
          {t("Change")}
        </ChangeLink>
      </Row>
      <Row>
        <div>
          <Label>{t("Shipping to")}:</Label> {shippingAddress.address}
          {shippingAddress.houseNumberOrName &&
            `,${shippingAddress.houseNumberOrName}`}
          <br />
          {shippingAddress.postalCode} {shippingAddress.city}
          {shippingAddress.region && (
            <>
              <br />
              {shippingAddress.region}
            </>
          )}
          , {getFullCountryName(shippingAddress.country)[0]}
        </div>
        <ChangeLink
          to="/"
          underline
          color={colors.primary}
          onClick={(e) => {
            e.preventDefault()
            setShowPayment(false)
            setShowSummary(false)
          }}
        >
          {t("Change")}
        </ChangeLink>
      </Row>
      <ShippingRow>
        <div>
          <Label>{t("Shipping")}:</Label>
          <div>
            <img src={shippingMethod.icon} alt={shippingMethod.label} />{" "}
            {shippingMethod.label} |{" "}
            {shippingPrice.toFormat(getFormat(shippingPrice))}
          </div>
        </div>
      </ShippingRow>
    </>
  )
}

const getFormat = (money) => (money.hasCents() ? "$0,0.00" : "$0,0")

const Currency = (currency) => (amount) => Dinero({ currency, amount })

export default Summary
