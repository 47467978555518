import React, { useState, useContext, useEffect, useRef } from "react"
import styled, { withTheme } from "styled-components"
import Radio from "../ui/Radio"
import { BrinkContext } from "../context/BrinkContext"
import Dinero from "dinero.js"
import * as events from "../context/utils/events"
import Loader from "../ui/Loader"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  form {
    display: flex;
  }
`

const ShippingOption = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 1rem 0 2rem;
  flex-wrap: wrap;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    align-items: center;
    flex: 1;
    display: flex;
  }
`

const Logo = styled.img`
  max-width: 8rem;
  max-height: 3.5rem;
  margin: 0.2rem 2rem 0 1.5rem;
`

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 5rem;
`

const Price = styled.span`
  font-size: 1.4rem;
`

const Name = styled.span`
  text-transform: uppercase;
  width: 100%;
  font-size: 1.4rem;

  span {
    font-weight: 700;
  }
`

const Description = styled.span`
  color: ${(p) => p.theme.colors.darkGrey};
  width: 100%;
  margin-top: 0.5rem;
  font-size: 1.3rem;
`

const Shipping = withTheme(({ sanityShippingOptions }) => {
  const {
    languageCode,
    getShippingOptions,
    currentStore,
    setShippingMethod,
    setIsLoading,
    cart,
    shippingMethod
  } = useContext(BrinkContext)
  const [shippingOptions, setShippingOptions] = useState([])
  const [cartPrice, setCartPrice] = useState(() => cart.totalPrice)
  const previousShippingOptions = useRef()

  const { currencyUnit } = currentStore
  const Amount = Currency(currencyUnit)

  const cartHasShipping = () => cart && cart.cartItems.find((c) => c.type === "shippingOption")

  useEffect(() => {
    getShippingOptions(currentStore.countryCode).then((options) => {
      const shippingOptions = sanityShippingOptions.nodes
        .map((shippingOption) => toShippingOption(options, shippingOption))
        .filter((x) => x)
        .sort((a, b) => a.sortOrder - b.sortOrder)
      setShippingOptions(shippingOptions)
      if (!shippingMethod || !cartHasShipping()) {
        setShippingMethod(shippingOptions[0])
      }
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.cartItems, cart.discounts, cart.store])

  useEffect(() => {
    previousShippingOptions.current = shippingOptions
  }, [shippingOptions])

  useEffect(() => {
    cartPrice !== cart.totalPrice && setCartPrice(cart.totalPrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const toShippingOption = (shippingOptions, shippingOption) => {
    const availableShippingOption = shippingOptions.find(
      (o) => o.id === shippingOption.id
    )
    return availableShippingOption
      ? {
          ...shippingOption,
          price: availableShippingOption.price,
          discount: availableShippingOption?.discount
        }
      : undefined
  }

  const handleShippingClick = (id) => {
    const newShippingMethod = shippingOptions.find((o) => o.id === id)
    if(newShippingMethod.id !== shippingMethod.id) events.addShipping(cart, newShippingMethod)
    setShippingMethod(newShippingMethod)
  }

  if(!shippingMethod) return <Loader/>

  return (
    <Container>
      {shippingOptions.map((shippingOption) => {
        const { mainImage, displayName, description, price } = shippingOption

        const shippingPrice = Amount(
          price[currentStore.currencyUnit]
        ).setLocale(languageCode)

        const displayPrice =
        !shippingOption.price[currencyUnit] ||
        (shippingOption?.discount && shippingOption.price[currencyUnit] === shippingOption.discount[currencyUnit])
          ? "Free"
          : shippingPrice.toFormat(getFormat(shippingPrice))

        return (
          <ShippingOption
            key={shippingOption.id}
            onClick={() => handleShippingClick(shippingOption.id)}
            htmlFor={shippingOption.id}
          >
            <Header>
              <div>
                <Radio
                  id={shippingOption.id}
                  name="shipping"
                  preSelected={
                    shippingOption.id === shippingMethod?.id
                  }
                />
                <Logo
                  src={mainImage.asset.url}
                  alt={displayName[languageCode] || displayName.en}
                />
              </div>
              <Price>{displayPrice}</Price>
            </Header>
            <Details>
              <Name>
                <span>{displayName[languageCode] || displayName.en}</span>
              </Name>
              <Description>
                {description[languageCode] || description.en}
              </Description>
            </Details>
          </ShippingOption>
        )
      })}
    </Container>
  )
})

const getFormat = (money) => (money.hasCents() ? "$0,0.00" : "$0,0")

const Currency = (currency) => (amount) => Dinero({ currency, amount })

export default Shipping
