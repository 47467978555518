import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Trans, useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import { Controller, useForm } from "react-hook-form"
import { BrinkContext } from "../context/BrinkContext"
import { subscribeToNewsletter } from "../context/utils/events/klaviyoEvents"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Box from "./Box"
import Link from "../../components/Link"
import Checkbox from "../../components/ui/Checkbox"
import Button from "../../components/ui/Button"
import BounceLoader from "react-spinners/BounceLoader"
import "@adyen/adyen-web/dist/adyen.css"
import "../../styles/adyen.css"
import * as events from "../context/utils/events"

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const PaymentBox = styled(Box)`
  padding: 2rem 0 0;

  h3 {
    margin-left: 2rem;

    ${MEDIA_MIN_MEDIUM} {
      margin-left: 3rem;
    }
  }
`

const Checkout = styled.div`
  padding: 0;
  margin: 3rem 0 2rem;
  width: 100%;

  form > div {
    padding-left: 1rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    margin: 0;
  }

  p {
    color: ${(p) => p.theme.colors.darkGrey};
    margin: 2rem 0 0;
    letter-spacing: 0;
  }
`

const Label = styled.label`
  margin-left: 1.5rem;
  margin-top: 0;
  text-transform: initial;
  line-height: 2rem;
  letter-spacing: 0;
  white-space: pre-wrap;
  letter-spacing: 0.15rem;
`

const ContinueButton = styled(Button)`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  margin-top: 1rem;
`

const Secure = styled.p`
  width: 100%;
  text-align: center;
  margin: 0 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.4rem;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 2rem 2rem;
  }

  i {
    font-size: 2.4rem;
    margin-right: 0.5rem;
    color: ${(p) => p.theme.colors.primary};
  }
`

const DropinContainer = styled.div`
  .adyen-checkout__threeds2__challenge,
  .adyen-checkout__iframe {
    width: 100%;
  }

  .adyen-checkout__iframe {
    margin: 0 2rem;
  }
`

const RetryButton = styled(Button)`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  margin-top: 1rem;
`

const Payment = () => {
  const { t } = useTranslation("translations")
  const {
    ADYEN_ENVIRONMENT,
    ADYEN_CLIENT_KEY,
    getPaymentMethods,
    cartToOrder,
    makePayment,
    cart,
    currentStore,
    languageCode,
    setOrder,
    setIsLoading,
    makeDetailsCall,
    setIsCartOpen,
    shippingAddress,
    billingAddress,
    isCartClosed,
    discountCode
  } = useContext(BrinkContext)

  const [displayError, setDisplayError] = useState(false)
  const [newsletterChecked, setNewsletterChecked] = useState(false)
  const [showPayPalButton, setShowPayPalButton] = useState(false)
  const [loadingCompleteOrder, setLoadingCompleteOrder] = useState(true)
  const [payPalActions, setPayPalActions] = useState(null)
  const [showRetryButton, setShowRetryButton] = useState(false)
  const [showCheckout, setShowCheckout] = useState(true)
  const dropin = useRef(null)
  const paypalComponent = useRef(null)
  const {
    control,
    handleSubmit,
    errors,
    triggerValidation,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  })

  const handleResult = (response, order) => {
    if (response.resultCode === "Authorised") {
      setOrder(order)
      setIsCartOpen(false)
      newsletterChecked &&
        subscribeToNewsletter(currentStore.currencyUnit, shippingAddress.email)
      navigate("/success/", {
        state: {
          order,
          cart,
          billingAddress,
          resultCode: response.resultCode
        }
      })
    } else {
      setDisplayError(true)
      setShowCheckout(false)
      setShowRetryButton(true)
    }
    setLoadingCompleteOrder(false)
  }

  useEffect(() => {
    if (!paypalComponent.current) return
    if (!showPayPalButton) return

    if (paypalComponent.current._node) {
      paypalComponent.current.remount()
    } else {
      paypalComponent.current.mount("#paypal-container")
    }
  }, [showPayPalButton])

  useEffect(() => {
    if (!payPalActions) return
    if (isValid) {
      payPalActions.enable()
    } else {
      payPalActions.disable()
    }
  }, [payPalActions, isValid])

  useEffect(() => {
    showRetryButton && dropin.current.unmount()
  }, [showRetryButton])

  useEffect(() => {
    const generateAdyenPayment = async () => {
      const [paymentMethods, order] = await Promise.all([
        getPaymentMethods(),
        cartToOrder()
      ])
      setIsLoading(false)

      const onAdditionalDetails = (state, adyen) => {
        setIsLoading(true)
        makeDetailsCall(order.id, state.data.details)
          .then((response) => {
            const { paymentResult, order } = response
            if (paymentResult.action) {
              adyen.handleAction(paymentResult.action)
              setOrder(order)
              setIsLoading(false)
              setShowCheckout(false)
            } else {
              handleResult(paymentResult, order)
              setIsLoading(false)
            }
          })
          .catch((error) => {
            throw Error(error)
          })
      }

      const sendEventsOnPlacedOrder = () => {
        if (newsletterChecked) {
          subscribeToNewsletter(currentStore.countryCode, shippingAddress.email)
          events.signup({
            email: shippingAddress.email,
            pageLocation: "/checkout/"
          })
        }
      }

      const onSubmit = (state, adyen) => {
        setLoadingCompleteOrder(true)
        if (
          state.data.paymentMethod.type === "applepay" &&
          !state.data.paymentMethod.applePayToken
        ) {
          return
        }

        sendEventsOnPlacedOrder()
        setDisplayError(false)
        setIsLoading(true)
        makePayment(
          order,
          state.data.paymentMethod,
          state.data.storePaymentMethod,
          state.data.browserInfo
        )
          .then((response) => {
            const { paymentResult, order } = response
            if (paymentResult.action) {
              setOrder(order)
              setIsLoading(false)
              setShowCheckout(false)
              adyen.handleAction(response.paymentResult.action)
            } else {
              handleResult(paymentResult, order)
              setIsLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)
            setIsLoading(false)
            navigate("/error/")
          })
      }

      const configuration = {
        amount: {
          value: cart.totalPriceWithDiscount,
          currency: currentStore.currencyUnit
        },
        paymentMethodsResponse: paymentMethods,
        clientKey: ADYEN_CLIENT_KEY,
        locale: languageCode,
        environment: ADYEN_ENVIRONMENT,
        showPayButton: false,
        onSubmit,
        onAdditionalDetails,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false,
            name: t("Credit or debit card")
          },
          applepay: {
            amount: {
              value: cart.totalPriceWithDiscount,
              currency: currentStore.currencyUnit
            },
            countryCode: currentStore.countryCode,
            onSubmit
          }
        }
      }

      import("@adyen/adyen-web")
        .then(({ default: AdyenCheckout }) => AdyenCheckout)
        .then((AdyenCheckout) => AdyenCheckout(configuration))
        .then((checkout) => {
          let paymentProvider = null
          let firstRender = true
          setLoadingCompleteOrder(false)
          if (dropin.current) {
            dropin.current.unmount()
          }
          dropin.current = checkout
            .create("dropin", {
              onSelect: ({ props: { type } }) => {
                setShowPayPalButton(type === "paypal")
                if (paymentProvider !== type && !firstRender) {
                  events.addPaymentInfo(cart, type, discountCode)
                }
                paymentProvider = type
                firstRender = false
              }
            })
            .mount("#dropin-container")

          if (paymentMethods.paymentMethods.find((p) => p.type === "paypal")) {
            paypalComponent.current = checkout.create("paypal", {
              showPayButton: true,
              onInit: (_, actions) => {
                actions.disable()
                setPayPalActions(actions)
              },
              onClick: () => triggerValidation(),
              onCancel: () => setShowCheckout(true),
              onError: () => setShowCheckout(true)
            })
          }
        })
        .catch((error) => console.error(error))
    }

    !isCartClosed() && generateAdyenPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discountCode])

  return (
    <>
      <PaymentBox>
        <h3>{t("Payment")}</h3>
        {!showRetryButton && (
          <Secure>
            <i className="fal fa-shield-check"></i>{" "}
            {t("All payments are encrypted and secure")}
          </Secure>
        )}
        {displayError && (
          <ErrorMessage>
            {t(
              "Something went wrong with your payment. Please try another option or contact our support."
            )}
          </ErrorMessage>
        )}
        {showRetryButton && (
          <RetryButton
            onClick={() => {
              setDisplayError(false)
              setShowCheckout(true)
              setShowRetryButton(false)
              dropin.current.update()
            }}
          >
            {t("Retry")}
          </RetryButton>
        )}

        <DropinContainer id="dropin-container" />
      </PaymentBox>
      <Checkout>
        <form onSubmit={handleSubmit(() => dropin.current.submit())}>
          {showCheckout && (
            <>
              <Controller
                as={Checkbox}
                control={control}
                type="checkbox"
                name="newsletter"
                value="newsletter"
                defaultValue={false}
                onChange={() => setNewsletterChecked(!newsletterChecked)}
              >
                <Label>{t("Subscribe to newsletter")}</Label>
              </Controller>

              <Controller
                as={Checkbox}
                control={control}
                rules={{
                  required: t(
                    "You need to accept the terms and agreements to be able to place an order"
                  )
                }}
                type="checkbox"
                name="agreement"
                value="agreement"
                errors={errors}
                defaultValue={false}
              >
                <Label>
                  <Trans i18nKey="termsAgreement">
                    I agree to the
                    <Link underline color="black" to="/terms-and-conditions/">
                      terms and conditions
                    </Link>
                    of c’est normal
                  </Trans>
                </Label>
              </Controller>
              {!showPayPalButton && (
                <ContinueButton type="submit" disabled={loadingCompleteOrder}>
                  {loadingCompleteOrder ? (
                    <BounceLoader color={"#ffffff"} size={35} />
                  ) : (
                    t("Complete order")
                  )}
                </ContinueButton>
              )}
            </>
          )}
          <div
            id="paypal-container"
            style={{ display: showPayPalButton ? "block" : "none" }}
          />
        </form>
      </Checkout>
    </>
  )
}

export default Payment
