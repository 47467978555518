import React from "react"
import styled from "styled-components"
import { Controller, ErrorMessage } from "react-hook-form"
import { useTranslation } from "react-i18next"
import CountryRegionData from "country-region-data/data"

const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 3.4rem;
`

const SelectWrapper = styled.div`
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    border: 0.5rem solid transparent;
    border-top: 0.6rem solid gray;
    border-bottom: 0 none;
    position: absolute;
    right: 2rem;
    top: 2.5rem;
  }
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  border-top: 0.2rem solid ${(p) => p.theme.colors.errors};
  width: 100%;
  padding-top: 0.4rem;
  letter-spacing: 0;
  font-size: 1.3rem;
`

const RegionSelector = ({
  control,
  errors,
  name,
  countryCode,
  defaultValue
}) => {
  const { t } = useTranslation("translations")

  if (!["US", "CA"].includes(countryCode)) return null

  const { regions } = CountryRegionData.find(
    (r) => r.countryShortCode === countryCode
  )

  return (
    <SelectContainer>
      <SelectWrapper>
        <Controller
          as={
            <select>
              <option value="">{t("Select state")}</option>
              {regions.map((r) => {
                return (
                  <option value={r.shortCode} key={r.shortCode}>
                    {r.name}
                  </option>
                )
              })}
            </select>
          }
          name={name}
          control={control}
          rules={{
            required: t("This is a required field")
          }}
          defaultValue={defaultValue}
          contextValue={defaultValue}
        />
      </SelectWrapper>
      {errors && (
        <ErrorMessage errors={errors} name={name}>
          {({ message }) => <Validation>{message}</Validation>}
        </ErrorMessage>
      )}
    </SelectContainer>
  )
}

export default RegionSelector
