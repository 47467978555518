import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"
import { BrinkContext } from "../../context/BrinkContext"
import Input from "../Input"
import CountrySelector from "./CountrySelector"
import RegionSelector from "./RegionSelector"
import {
  parsePhoneNumberFromString,
  getCountryCallingCode
} from "libphonenumber-js/min"

const Container = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const Firstname = styled(Input)`
  width: 50%;
`

const Lastname = styled(Input)`
  width: calc(50% - 1rem);
  margin-left: 1rem;
`

const PostalCode = styled(Input)`
  width: 50%;
`

const City = styled(Input)`
  width: calc(50% - 1rem);
  margin-left: 1rem;
`

const BillingAddress = ({ control, errors, setValue }) => {
  const { t } = useTranslation("translations")
  const {
    currentStore,
    billingAddress,
    countryWhiteList,
    languageCode,
    stores,
    setCurrentStore
  } = useContext(BrinkContext)

  const setEmailFocused = (value) => {
    _setEmailFocused(value)
  }
  const [emailFocused, _setEmailFocused] = useState(false)

  const validatePhoneNumber = (data) => {
    const phoneNumber = parsePhoneNumberFromString(
      data || "",
      currentStore.countryCode
    )
    const isPossible = phoneNumber ? phoneNumber.isPossible() : false
    return isPossible || t("Please supply a valid phone number")
  }

  const houseNumberOrNameBlackList = ["SE", "BE"]

  useEffect(() => {
    setValue("billing", [
      { email: billingAddress.email },
      { firstName: billingAddress.firstName },
      { lastName: billingAddress.lastName },
      { phone: billingAddress.phone },
      { address: billingAddress.address },
      { houseNumberOrName: billingAddress.houseNumberOrName },
      { postalCode: billingAddress.postalCode },
      { city: billingAddress.city },
      { region: billingAddress.region },
      { country: billingAddress.country }
    ])
  }, [setValue, billingAddress])

  return (
    <Container>
      <Controller
        as={
          <Input
            onFocus={() => {
              !emailFocused && setEmailFocused(true)
            }}
          />
        }
        control={control}
        rules={{
          required: t("This is a required field"),
          maxLength: {
            value: 80,
            message: t("Max 80 characters")
          },
          pattern: {
            value:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
            message: t("Please supply a valid e-mail address")
          }
        }}
        type="email"
        name="billing[email]"
        label={t("E-mail")}
        defaultValue={billingAddress.email}
        contextValue={billingAddress.email}
        errors={errors}
      />

      <Controller
        as={Firstname}
        control={control}
        rules={{
          required: t("This is a required field"),
          maxLength: {
            value: 25,
            message: t("Max 25 characters")
          }
        }}
        type="text"
        name="billing[firstName]"
        label={t("First name")}
        defaultValue={billingAddress.firstName}
        contextValue={billingAddress.firstName}
        errors={errors}
      />

      <Controller
        as={Lastname}
        control={control}
        rules={{
          required: t("This is a required field"),
          maxLength: {
            value: 25,
            message: t("Max 25 characters")
          }
        }}
        type="text"
        name="billing[lastName]"
        label={t("Last name")}
        defaultValue={billingAddress.lastName}
        contextValue={billingAddress.lastName}
        errors={errors}
      />

      <Controller
        as={Input}
        control={control}
        rules={{
          validate: (phoneNumber) => validatePhoneNumber(phoneNumber),
          maxLength: {
            value: 30,
            message: t("Max 30 characters")
          }
        }}
        type="tel"
        name="billing[phone]"
        label={`Telephone (ex: +${getCountryCallingCode(
          currentStore.countryCode
        )} XXXXXXXX)`}
        defaultValue={billingAddress.phone}
        contextValue={billingAddress.phone}
        errors={errors}
      />

      <Controller
        as={Input}
        control={control}
        rules={{
          required: t("This is a required field"),
          maxLength: {
            value: 100,
            message: t("Max 100 characters")
          }
        }}
        type="text"
        name="billing[address]"
        label={t("Address")}
        defaultValue={billingAddress.address}
        contextValue={billingAddress.address}
        errors={errors}
      />

      {!houseNumberOrNameBlackList.includes(currentStore.countryCode) && (
        <Controller
          as={Input}
          control={control}
          rules={{
            maxLength: {
              value: 50,
              message: t("Max 50 characters")
            }
          }}
          type="text"
          name="billing[houseNumberOrName]"
          label={t("House number or name")}
          defaultValue={billingAddress.houseNumberOrName}
          contextValue={billingAddress.houseNumberOrName}
          errors={errors}
        />
      )}

      <Controller
        as={PostalCode}
        control={control}
        rules={{
          required: t("This is a required field"),
          maxLength: {
            value: 20,
            message: t("Max 20 characters")
          }
        }}
        type="text"
        name="billing[postalCode]"
        label={t("Postal code")}
        defaultValue={billingAddress.postalCode}
        contextValue={billingAddress.postalCode}
        errors={errors}
      />

      <Controller
        as={City}
        control={control}
        rules={{
          required: t("This is a required field"),
          maxLength: {
            value: 30,
            message: t("Max 30 characters")
          }
        }}
        type="text"
        name="billing[city]"
        label={t("City")}
        defaultValue={billingAddress.city}
        contextValue={billingAddress.city}
        errors={errors}
      />

      <RegionSelector
        countryCode={currentStore.countryCode}
        control={control}
        name="billing[region]"
        errors={errors}
        defaultValue={billingAddress.region}
      />

      <CountrySelector
        setCurrentStore={setCurrentStore}
        countryWhiteList={countryWhiteList}
        control={control}
        stores = {stores}
        currentStore={currentStore}
        languageCode={languageCode}
        name="billing[country]"
        errors={errors}
      />
    </Container>
  )
}

export default BillingAddress
