import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Container = styled.div`
  position: relative;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 2rem 2rem 3rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 3rem 3rem;
    margin-bottom: 3rem;
  }

  h3 {
    padding: 0 1rem;
    font-size: 1.6rem;
    margin: 1rem 0 3rem;
    position: relative;
    border-left: 0.5rem solid ${(p) => p.theme.colors.primary};

    &:before {
      position: absolute;
      height: 0.3rem;
      background: #f8c3d6;
      left: 2rem;
      right: 2rem;
      bottom: -0.8rem;
    }
  }
`

const Box = ({ children, ...other }) => (
  <Container {...other}>{children}</Container>
)

export default Box
